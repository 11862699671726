import React from 'react'
import {
   Box, Grid, Typography, 
  Avatar, CircularProgress } from '@mui/material';

// const friends = [
//     {
//       name: 'Sara',
//       id: 'sara',
//       avatar: 'https://i.pravatar.cc/300'
//     },
//     {
//       name: 'Dany',
//       id: 'dany',
//       avatar: 'https://i.pravatar.cc/300'
//     },
//     {
//       name: 'Tom',
//       id: 'tom',
//       avatar: 'https://i.pravatar.cc/300'
//     },
//     {
//       name: 'Sara',
//       id: 'sara',
//       avatar: 'https://i.pravatar.cc/300'
//     },
//     {
//       name: 'Dany',
//       id: 'dany',
//       avatar: 'https://i.pravatar.cc/300'
//     },
//     {
//       name: 'Tom',
//       id: 'tom',
//       avatar: 'https://i.pravatar.cc/300'
//     },
//     {
//       name: 'Sara',
//       id: 'sara',
//       avatar: 'https://i.pravatar.cc/300'
//     },
//     {
//       name: 'Dany',
//       id: 'dany',
//       avatar: 'https://i.pravatar.cc/300'
//     },
//     {
//       name: 'Tom',
//       id: 'tom',
//       avatar: 'https://i.pravatar.cc/300'
//     },
//     {
//       name: 'Sara',
//       id: 'sara',
//       avatar: 'https://i.pravatar.cc/300'
//     },
//     {
//       name: 'Dany',
//       id: 'dany',
//       avatar: 'https://i.pravatar.cc/300'
//     },
//     {
//       name: 'Tom',
//       id: 'tom',
//       avatar: 'https://i.pravatar.cc/300'
//     },
//  ]

function FriendsList(props) {
  const {  friends, isFetchingFriends, ENDPOINT } = props;

  return (
     <Box display="flex" flexDirection={'column'} position={'absolute'} bottom={'-140px'} left={'5vw'} width={'90%'} height='40vh' sx={{ alignItems: 'center', background:'#47b9ff', borderRadius: '20px', my: 2 }}>
        <Grid item xs={12} display="flex" flexDirection={'column'} justifyContent="center" alignItems="center" mx={'auto'} mb={2} pt={2}>
            <Typography variant="h6" fontFamily="Poppins-Bold, sans-serif" fontWeight="bold" zIndex={1} sx={{ color: 'white' }}>
                Your frens ({friends? friends.length : 0})
            </Typography> 
        </Grid>   
        {/* freinds list is their avatar squares 50px 50px and they are in box */}
        <Box display="flex" flexDirection={'row'} flexWrap={'wrap'} justifyContent="center" alignItems="flex-start" width={'101%'} height={'35vh'} overflow={'auto'}>
            {friends?
              friends.map((friend, index) => (
              <Box key={index} display="flex" flexDirection={'column'}>
                  <Avatar alt={friend.firstname} src={`${ENDPOINT}/public/users/avatars/${friend.userId}.jpg`} sx={{ width: 69, height: 69, mx: 1, border: '3px solid white', borderRadius: '20px' }} />
                  {/* name of friend */}
                  <Typography variant="p" fontFamily="Sora, sans-serif" fontWeight="bold" zIndex={1} width={'100%'} mb={1.75} mt={0.5} sx={{ color: 'white' }}>
                      {friend.firstname.length > 5 ? friend.firstname.slice(0, 5) + '...' : friend.firstname}
                  </Typography>
              </Box>
              ))
            :  
            isFetchingFriends?
               <CircularProgress size={70} thickness={7} sx={{ color: '#BEB7FF', ml: 4, mt: 3 }} />
            :
             <Typography variant="h5" fontFamily="Poppins-Bold, sans-serif" fontWeight="bold" zIndex={1} mt={5} sx={{ color: 'white' }}>
                 No any friends :(
             </Typography>
            }
        </Box>
     </Box>
  )
}

export default FriendsList