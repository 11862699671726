import React, { useState, useEffect } from 'react'
import { 
    Container, CssBaseline, AppBar, Toolbar, Typography, List, Grid,
    ListItem, ListItemIcon, ListItemText, Checkbox, IconButton, CircularProgress } from '@mui/material';
import { FiberManualRecord, CheckCircle, PlayCircleOutline } from '@mui/icons-material';
import { Telegram, X, VideoLibrary, HistoryEdu } from '@mui/icons-material';
import axios from 'axios';

// const tasks = [
//     { type: 'Telegram', link:'https://t.me/wonka_tips', title: 'Join Wonka Announcement', rewards: 400, completed: false },
//     { type: 'Telegram', link:'https://t.me/wonka_tips', title: 'Join Wonka Community', rewards: 250, completed: true },
//     { type: 'X', link:'https://x.com/wonka_tips', title: 'Follow Wonka X', rewards: 250, completed: false },
//     { type: 'Youtube', link:'https://youtube.com/wonka_tips', title: 'Subscribe to Wonka Youtube', rewards: 250, completed: false },
//     { type: 'Farcaster', link:'https://warpcast.com/~/channel/wonka', title: 'Follow Wonka Farcaster', rewards: 250, completed: true },
// ];

const iconsList = (type) => {
    const icons = {
        'Telegram': <Telegram htmlColor='#E2E2E2' />,
        'X': <X htmlColor='#E2E2E2' />,
        'Youtube': <VideoLibrary htmlColor='#E2E2E2' />,
        'Farcaster': <HistoryEdu htmlColor='#E2E2E2' />,
    }
    return icons[type];
}

const TaskList = ({ tasks, userId, telApp, setActiveTask, setOpenConfirmPopup }) => {
   const handleTaskClick = (index) => {
      if (tasks[index - 1].usersCompleted.includes(String(userId))) {
        telApp?.showAlert('You have already completed this task!');
      } else {
        setOpenConfirmPopup(true);
        setActiveTask(index);
      }
   }

    return (
     <Grid container spacing={2} justify="center" position={'relative'} bottom={'15vh'} height={'57vh'} sx={{ overflowY: 'scroll', flexDirection: 'row', flexWrap: 'wrap', alignContent: 'space-between', justifyContent: 'space-around', alignItems: 'center'   }}>
        <List sx={{ alignItems: 'center', alignContent: 'center' }}>
          <Typography variant="h6" fontFamily="Poppins-Bold, sans-serif" fontWeight="bold" sx={{ color: '#BEB7FF', ml: 2, mb: 1 }}>Task List</Typography>
          {tasks?
            tasks.map((task, index) => (
            <ListItem key={index} onClick={() => handleTaskClick(index +1)} button divider sx={{ background: 'royalblue', backdropFilter: 'blur(2px)', borderRadius: '15px', mb: 1, ml: 2, width: '90vw', '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.7)' } }}>
               <ListItemIcon>
                  {iconsList(task.type)}
               </ListItemIcon>
              <ListItemText primary={task.title} secondary={'+'+ task.rewards + ' Points'} primaryTypographyProps={{ fontFamily: 'Sora, sans-serif', fontWeight: 'bold', color: 'white' }} secondaryTypographyProps={{ fontFamily: 'Poppins-Medium, sans-serif', color: 'white' }} />
              {task.usersCompleted.includes(String(userId)) ? (
                // green su
                <IconButton edge="end" aria-label="completed" sx={{ color: '#fff' }}>
                  <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Symbols/Ok%20Button.webp" alt="Check Mark Button" width="30" height="30" />
                </IconButton>
              ) : (
                <IconButton edge="end" aria-label="play" sx={{ color: '#fff' }}>
                  <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Symbols/Exclamation%20Question%20Mark.webp" alt="Check Mark Button" width="30" height="30" />
                </IconButton>
              )}
            </ListItem>
           )) :
            <CircularProgress size={70} thickness={7} sx={{ color: '#BEB7FF', ml: 4, mt: 3 }} />
          }
        </List>
    </Grid>
    );
};
  
export default TaskList;