import React from 'react';
import { Button, Typography, Box, List, ListItem } from '@mui/material';
import ProgressBar from './ProgressBar';

const Page2 = ({ nextPage, backgroundSource }) => {
  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        backgroundImage: `url('${backgroundSource}')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
      }}
    >
      <ProgressBar step={2} />
      <Typography
        variant="h6"
        align="center"
        fontFamily="Sora, sans-serif"
        fontWeight="bold"
        gutterBottom
        sx={{ color: 'white', backgroundColor: 'rgba(0,0,0,0.5)', borderRadius: '20px', width: '85vw', maxHeight: '40vh', overflowY: 'scroll', overflowX: 'hidden', mx: 'auto', py: 2, px: 1 }}
      >
        Early Benefits
         <List sx={{ width: '85vw', mx: 'auto', mt: 1 }}>
         <ListItem> • Advanced Farming Access: Earlies can start farming 10 days before the public launch. </ListItem>
         <ListItem> • Unlimited Invitations: During the registration period, earlies can invite an unlimited number of friends, while invite codes will be limited at the public launch. </ListItem>
         <ListItem> • Enhanced Referral Points: Earlies earn 75 points per invite + 3% of their referrals' points. After the public launch, only 3% of referrals' points will be awarded. </ListItem>
         <ListItem> • Exclusive Invitation Period: Invitations will be closed during the early farming phase. </ListItem>
         <ListItem> • Points for Active Friends: You will receive points when your friends advance to a higher league. Therefore, try to invite active and powerful friends. </ListItem>
         </List>
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={nextPage}
        sx={{ mx: 'auto', width: '85vw', mb: 2, py: 1, fontSize: '1.15rem', fontFamily: 'Sora, sans-serif', fontWeight: 'bold', borderRadius: '20px', textTransform: 'capitalize' }}
      >
        Continue Registration
      </Button>
    </Box>
  );
};

export default Page2;
