import React from 'react'
import {
   Box, Grid, Typography, 
  Avatar, CircularProgress } from '@mui/material';

// const friends = [
//     {
//       name: 'Sara',
//       id: 'sara',
//       avatar: 'https://i.pravatar.cc/300'
//     },
//     {
//       name: 'Dany',
//       id: 'dany',
//       avatar: 'https://i.pravatar.cc/300'
//     },
//     {
//       name: 'Tom',
//       id: 'tom',
//       avatar: 'https://i.pravatar.cc/300'
//     },
//     {
//       name: 'Sara',
//       id: 'sara',
//       avatar: 'https://i.pravatar.cc/300'
//     },
//     {
//       name: 'Dany',
//       id: 'dany',
//       avatar: 'https://i.pravatar.cc/300'
//     },
//     {
//       name: 'Tom',
//       id: 'tom',
//       avatar: 'https://i.pravatar.cc/300'
//     },
//     {
//       name: 'Sara',
//       id: 'sara',
//       avatar: 'https://i.pravatar.cc/300'
//     },
//     {
//       name: 'Dany',
//       id: 'dany',
//       avatar: 'https://i.pravatar.cc/300'
//     },
//     {
//       name: 'Tom',
//       id: 'tom',
//       avatar: 'https://i.pravatar.cc/300'
//     },
//     {
//       name: 'Sara',
//       id: 'sara',
//       avatar: 'https://i.pravatar.cc/300'
//     },
//     {
//       name: 'Dany',
//       id: 'dany',
//       avatar: 'https://i.pravatar.cc/300'
//     },
//     {
//       name: 'Tom',
//       id: 'tom',
//       avatar: 'https://i.pravatar.cc/300'
//     },
//  ]

function FriendsList(props) {
  const {  friends, isFetchingFriends, ENDPOINT } = props;

  const handleRanckingEmojis = () => {
    if (!friends || friends.length === 0) return;

    let emojiUrl;
    if (friends.length <= 3) {
         emojiUrl = 'https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Activity/3rd%20Place%20Medal.webp';
    } else if (friends.length > 3 && friends.length <= 6) {
         emojiUrl = 'https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Activity/2nd%20Place%20Medal.webp';
    } else if (friends.length > 6) {
         emojiUrl = 'https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Activity/1st%20Place%20Medal.webp';
    } else if (friends.length > 12) {
         emojiUrl = 'https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Activity/Sports%20Medal.webp';
    } else {
         emojiUrl = 'https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Activity/Military%20Medal.webp';
    }

    return emojiUrl;
  }

  return (
     <Box display="flex" flexDirection={'column'} position={'relative'} bottom={'10vh'} width={'93vw'} height='38vh' sx={{ alignItems: 'center', mb: 1, background:'dodgerblue', borderRadius: '20px',  }}>
        <Grid item xs={12} display="flex" flexDirection={'column'} justifyContent="center" alignItems="center" mx={'auto'} mb={2} pt={2}>
            <Typography variant="h6" fontFamily="Poppins-Bold, sans-serif" fontWeight="bold" zIndex={1} sx={{ color: 'white' }}>
                Your friends ({friends? friends.length : 0})
            </Typography> 
            <Box component="img" loading="lazy" src={handleRanckingEmojis()} alt="WillyWonka" sx={{ width: '15vw', position: 'absolute', top: '0vh', right: '2vw' }} />
        </Grid>   
        {/* freinds list is their avatar squares 50px 50px and they are in box */}
        <Box display="flex" flexDirection={'row'} flexWrap={'wrap'} justifyContent="center" alignItems="flex-start" width={'101%'} height={'35vh'} overflow={'auto'}>
            {friends?
              friends.map((friend, index) => (
              <Box key={index} display="flex" flexDirection={'column'}>
                  <Avatar alt={friend.firstname} src={`${ENDPOINT}/public/users/avatars/${friend.userId}.jpg`} sx={{ width: 69, height: 69, mx: 1, border: '3px solid white', borderRadius: '20px' }} />
                  {/* name of friend */}
                  <Typography variant="p" fontFamily="Sora, sans-serif" fontWeight="bold" zIndex={1} width={'100%'} ml={2} mb={1.75} mt={0.5} sx={{ color: 'white' }}>
                      {friend.firstname.length > 5 ? friend.firstname.slice(0, 5) + '...' : friend.firstname}
                  </Typography>
              </Box>
              ))
            :  
            isFetchingFriends?
               <CircularProgress size={70} thickness={7} sx={{ color: '#BEB7FF', ml: 4, mt: 3 }} />
            :
             <Typography variant="h5" fontFamily="Poppins-Bold, sans-serif" fontWeight="bold" zIndex={1} mt={5} sx={{ color: 'white' }}>
                 No any friends :(
             </Typography>
            }
        </Box>
     </Box>
  )
}

export default FriendsList