import React, { useState, useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material';
import GiftIcon from '@mui/icons-material/CardGiftcard';
import CopyAllSharp from '@mui/icons-material/CopyAllSharp';
import { styled } from '@mui/system';
import FriendsList from './Referral/FriendsList';
import ShareLink from './Referral/ShareLink';
import ButtonTabs from './Tabs/Tabs';
import Confetti from './Confetti/Confetti';
import confetti from '../assets/images/gifs/confetti.gif';
import cloud from '../assets/images/cloud-bg.png';
import axios from 'axios';


const PopupImage = styled('img')({
    width: '60vw',
    height: '20vw',
    position: 'absolute',
    top: '7vh',
    left: '50%',
    transform: 'translateX(-50%)',
});

function ReferralsPage(props) {
  const {userData, openConfetti, setOpenConfetti, telApp, ENDPOINT} = props;
  const [userId, setUserId] = useState(null);
  const [friends, setFriends] = useState(null);
  const [isFetchingFriends, setIsFetchingFriends] = useState(false);
  const [inviteLink, setInviteLink] = useState(null);
  const [shareMessageLink, setShareMessageLink] = useState(null);

  useEffect(() => {
    const getFriends = async() => {
       setIsFetchingFriends(true);
       const response = await axios.get(`${ENDPOINT}/referrals/${userId}`);

       const friends = typeof response.data.referrals === 'string' ? null : response.data.referrals;
       setFriends(friends);

       setTimeout(() => {
          setIsFetchingFriends(false);
       }, 2000);
    }    
    
    getFriends();    

    setInviteLink(createInviteLink(userId));
    const message = `Join Wonka Tips now! 🎉
Get daily allowance and enjoy your free tipping. \n\nClick below link to get started. \n${createInviteLink(userId)}`;
    setShareMessageLink(createShareMessageLink(message));
  }, [userId]);

  useEffect(() => {
       if (userData) {
           setUserId(userData.id);
       }
  }, [userData]);

  const createInviteLink = (userId) => {
    return `https://t.me/WonkaTipsBot/launch?startapp=${userId}`
  }

  const createShareMessageLink = (text) => {
    // URL-encode the text
    const encodedText = encodeURIComponent(text);
    // Format the tg://msg_url link
    const link = `https://t.me/share/url?url=${encodedText}`;
    return link;
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(inviteLink);
    telApp.showAlert('Invite link copied!');
  }

  return (
    <Box sx={{ height: '100vh', minHeight: '100vh', display: 'flex', backgroundSize: '300px'}}>
        <Grid container justify="center" sx={{ height: '100vh', flexDirection: 'row', flexWrap: 'wrap', alignContent: 'space-between', justifyContent: 'center', alignItems: 'center'   }}>          
            {/* Top header */}
            <Grid item xs={12} display="flex" flexDirection={'column'} justifyContent="center" alignItems="center" height='10vh' sx={{ mt: 10 }}>
                <Typography variant="h5" fontFamily="Poppins-Bold, sans-serif" fontWeight="bold" position={'relative'} bottom={'15px'} left={'2px'} letterSpacing={1} zIndex={1} sx={{ color: 'white' }}>Invite friends!</Typography>
                <PopupImage src={cloud} alt="Popup Image" loading='eager' />
            </Grid>

           {/* Top section */}
            <Box display="flex" flexDirection={'row'} justifyContent="center" alignItems="center" position={'relative'} bottom={'5vh'} width={'93vw'} height='13vh' sx={{ alignItems: 'center', mb: 1, background:'dodgerblue', borderRadius: '20px',  }}>
                <Grid item xs={12} display="flex" flexDirection={'column'} justifyContent="center" alignItems="center" mx={'auto'} mb={2} pt={2} pl={2}>
                  <Typography variant="p" fontFamily="Sora, sans-serif" fontWeight="bold" zIndex={1} width={'100%'} sx={{ color: 'white' }}>Earn 3% of your friends points each time they advance to a higher league</Typography>
                  <Box component="img" loading="lazy" src={'https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Food%20and%20Drink/Custard.webp'} alt="Custard" sx={{ width: '15vw', position: 'absolute', top: '6vh', left: '76vw', rotate: '-20deg' }} />
              </Grid>
            </Box>

           {/* Friend list */}
            <FriendsList friends={friends} isFetchingFriends={isFetchingFriends} userId={userId} telApp={telApp} ENDPOINT={ENDPOINT} />

           {/* Share link */}
            <ShareLink inviteLink={inviteLink} shareMessageLink={shareMessageLink} copyToClipboard={copyToClipboard} telApp={telApp} />
            
           {/* Confetti popup */}
            <Confetti openConfetti={openConfetti} x={0.5} y={0.85} />

            {/* Bottom footer */}
            <ButtonTabs tabValue={4}/>
        </Grid>
    </Box>
  )
}

export default ReferralsPage;