import React, { useState, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { calculateRemainingTime_DHMS } from '../utils/Time'
import LoadingScreen from './Home/LoadingScreen';

import Page1 from './Waitlist/Page1';
import Page2 from './Waitlist/Page2';
import Page3 from './Waitlist/Page3';
import Page4 from './Waitlist/Page4';
import usePreloadImages from './Hooks/usePreloadImages';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0088cc',
    },
  },
});

const imageUrls = [
  'https://app.wonka.tips/api/public/web/whitelist-pages/page1-bg.jpg',
  'https://images.nightcafe.studio/jobs/i54l7R5ArW25bvBSzfgh/i54l7R5ArW25bvBSzfgh--1--v08qu.jpg?tr=w-1600,c-at_max',
  'https://images.nightcafe.studio/jobs/oJ7plm8W7X0wZccIAhbT/oJ7plm8W7X0wZccIAhbT--1--lqi92.jpg?tr=w-1600,c-at_max',
];

function Whitelist(props) {
  const {userData, openConfetti, setOpenConfetti, telApp, ENDPOINT} = props;
  const [page, setPage] = useState(1);
  const [privateLaunchTime, setPrivateLaunchTime] = useState(false);
  const [launchRemainingTime, setLaunchRemainingTime] = useState(null);
  const imagesLoaded = usePreloadImages(imageUrls);

  const nextPage = () => setPage(page + 1);
  const launchTime = async () => {
    const response = await axios.get(`${ENDPOINT}/private-launch-time`);
    setPrivateLaunchTime(response.data.launchTime);
  }
  
  useEffect(() => {
    launchTime();
    const passedSteps = localStorage.getItem('waitlistStepsCompleted');
    if (passedSteps) {
      setPage(4);
    } 
  }, [page]);

  useEffect(() => {
    if (imagesLoaded && page === 4) {
      const calculateRemainingTime = () => {
          const remainingTime = calculateRemainingTime_DHMS(Date.now(), privateLaunchTime);
          setLaunchRemainingTime(remainingTime);
      };

      const startCalculating = () => {
        calculateRemainingTime(); // Initial call after delay
        const intervalId = setInterval(calculateRemainingTime, 1000); // Subsequent calls every second

        return () => clearInterval(intervalId); // Cleanup
      };

      const timeoutId = setTimeout(startCalculating, 4500); // 4-second delay

      return () => {
        clearTimeout(timeoutId); // Cleanup the timeout
        clearInterval(); // Cleanup the interval
      };
    }
  }, [imagesLoaded, page]);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={false} disableGutters sx={{ height: page === 4 ? '120vh' : 'auto', maxHeight: '100vh', overflowY: 'scroll' }}>
         <LoadingScreen loadedAppData={imagesLoaded} whitelistGradient={true} />
        {page === 1 && <Page1 nextPage={nextPage} backgroundSource={imageUrls[0]} />}
        {page === 2 && <Page2 nextPage={nextPage} backgroundSource={imageUrls[1]} />}
        {page === 3 && <Page3 
           nextPage={nextPage} 
           backgroundSource={imageUrls[2]} 
           userData={userData} 
           telApp={telApp}
           ENDPOINT={ENDPOINT} />}
        {page === 4 && <Page4
            userData={userData}
            remainingLaunchTime={launchRemainingTime}
            openConfetti={openConfetti}
            setOpenConfetti={setOpenConfetti}
            telApp={telApp}
            ENDPOINT={ENDPOINT}
        />}
      </Container>
    </ThemeProvider>
  );
}

export default Whitelist;
