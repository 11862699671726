import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system'; 
import Wheel from './Rewards/SpinWheelGame'; // Ensure correct import path
import BottomTab from './Tabs/Tabs';
import cloud from '../assets/images/cloud-bg.png';

const PopupImage = styled('img')({
  width: '80vw',
  height: '30vw',
  position: 'absolute',
  top: '7vh',
  left: '50%',
  transform: 'translateX(-50%)',
});

function Rewards(props) {
  const { telApp, ENDPOINT } = props;
  const items = ['25', '50', '75', '100', '200', '500', '1000'];

  return (
    <Box sx={{ height: '100vh', minHeight: '100vh', display: 'flex'}}>
        <Grid container spacing={2} justifyContent="center" sx={{ height: '100vh', flexDirection: 'row', flexWrap: 'wrap', alignContent: 'space-between', justifyContent: 'space-around', alignItems: 'center' }}>
           <Grid item xs={12} display="flex" flexDirection={'column'} justifyContent="center" alignItems="center" height='10vh' sx={{ mb: 8, mt: 12 }}>
                <Typography variant="h5" fontFamily="Poppins-Bold, sans-serif" fontWeight="bold" zIndex={1} sx={{ color: 'white' }}>Eran Rewards</Typography>
                <Typography variant="h5" fontFamily="Poppins-Bold, sans-serif" fontWeight="bold" zIndex={1} sx={{ color: 'white' }}>In Everyday</Typography>
                <PopupImage src={cloud} alt="Popup Image" loading='eager' />
                <Box component="img" loading="lazy" src={'https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Activity/Party%20Popper.webp'} alt="Mirror Ball" sx={{ width: '80px', position: 'absolute', top: '16vh', right: '11vw' }} />
            </Grid>

            {/* <Box component="img" loading="lazy" src={sugarCandy} alt="Eating Chocolates" sx={{ width: '80px', position: 'absolute', top: '12vh', right: '8vw', rotate: '20deg' }} />
            <Box component="img" loading="lazy" src={sugarCandy} alt="Eating Chocolates" sx={{ width: '70px', position: 'absolute', top: '11vh', right: '15vw', filter: 'hue-rotate(180deg)' }} /> */}

           {/* Spin Wheel Game */}
           <Wheel items={items} telApp={telApp} />
           
           {/* Bottom Tab */}
           <BottomTab tabValue={3} />
        </Grid>
    </Box>
  )
}

export default Rewards;
