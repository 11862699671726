import React from 'react';
import { Link } from 'react-router-dom';
import { Tabs, Tab, Box, Grid } from '@mui/material';
import { styled } from '@mui/system';

import HomeIcon from '@mui/icons-material/Home';
import TaskAltIcon from '@mui/icons-material/TaskAltRounded';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcardOutlined';
import ReferralsIcon from '@mui/icons-material/People';

// if tab active, make it bold and green
const StyledTab = styled(Tab)(({ theme, isActive }) => ({
  color: isActive ? '#f4c300' : 'white',
  opacity: isActive ? 1 : 0.65,
  fontFamily: 'Poppins-Bold',
  fontSize: '17px',
  textTransform: 'Capitalize',
  fontWeight: isActive ? 800 : 600,
  minWidth: '20vw',
  borderBottom: isActive ? '5px solid #f4c300' : '5px solid rgba(255, 255, 255, 0.5)',
}));

function BottomTabs({ userPlatform, tabValue, needGutter = true }) {
  return (
    <Grid item xs={12} position={'fixed'} bottom={needGutter ? '3.5vh' : '0vh'} pt={'0vh !important'} mr={'auto'} ml={'auto'} width={'100vw'} zIndex={999}>
      <Box sx={{ background: 'transparent', borderRadius: '0px', display: 'flex', backdropFilter: 'blur(10px)', justifyContent: 'space-around', alignItems: 'center', '& .MuiTabs-indicator':{ display: 'none' } }}>
        <Tabs centered value={tabValue}>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <StyledTab isActive={tabValue === 1} value={'1'} icon={<HomeIcon />} label="Home" />
          </Link>
          <Link to="/task" style={{ textDecoration: 'none' }}>
            <StyledTab isActive={tabValue === 2} value={'2'} icon={<TaskAltIcon />} label="Earn" />
          </Link>
          <Link to="/rewards" style={{ textDecoration: 'none' }}>
            <StyledTab isActive={tabValue === 3} value={'3'} icon={<CardGiftcardIcon />} label="Rewards" />
          </Link>
          <Link to="/referrals" style={{ textDecoration: 'none' }}>
            <StyledTab isActive={tabValue === 4} value={'4'} icon={<ReferralsIcon />} label="Friends" />
          </Link>
        </Tabs>
      </Box>
    </Grid>
  );
}

export default BottomTabs;
