import React from 'react';
import Confetti from 'react-confetti-boom';

function ConfettiComponent(props) {
  const { 
    openConfetti = false, 
    x = 0.5, 
    y = 0.85, 
    particleCount = 25, 
    shapeSize = 15,
    spreadDeg = 90,
    effectInterval=500
   } = props
  return (
     <span style={{ zIndex: 99999 }}>
          {/* Confetti Popup */}
          {openConfetti && <Confetti
              mode='boom'
              x={x}
              y={y}
              particleCount={particleCount}
              deg={270}
              shapeSize={shapeSize}
              spreadDeg={spreadDeg}
              effectInterval={effectInterval}
              effectCount={1}
              colors={['#05b5f3', '#ff49d0', '#ffd384', '#fff9b0', '#3498db']}
            /> }
     </span>
  )
}

export default ConfettiComponent;