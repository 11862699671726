

const calculateElapsedTime_HMS = (givenTimeMs) => {
    const currentTimeMs = Date.now();
    const elapsedTimeMs = currentTimeMs - givenTimeMs;

    if (elapsedTimeMs < 0) {
        throw new Error("The given timestamp is in the future.");
    }

    // Convert elapsed time to seconds
    const elapsedTimeS = elapsedTimeMs / 1000;

    // Calculate hours, minutes, and seconds
    const elapsedHours = Math.floor(elapsedTimeS / 3600);
    const elapsedMinutes = Math.floor((elapsedTimeS % 3600) / 60);
    const elapsedSeconds = Math.floor(elapsedTimeS % 60);

    return {
        hours: elapsedHours,
        minutes: elapsedMinutes,
        seconds: elapsedSeconds
    };
}

function calculateRemainingTime_HMS(startTimeMs, endTimeMs) {
    const currentTimeMs = Date.now(); // Current time in milliseconds

    // Ensure endTimeMs is in the future relative to startTimeMs
    if (endTimeMs <= startTimeMs) {
        return {
            hours: 0,
            minutes: 0,
            seconds: 0
        };
    }

    // Calculate remaining time in milliseconds
    const remainingTimeMs = endTimeMs - currentTimeMs;

    if (remainingTimeMs <= 0) {
        return {
            hours: 0,
            minutes: 0,
            seconds: 0
        };
    }

    // Convert remaining time to seconds
    const remainingTimeS = Math.floor(remainingTimeMs / 1000);

    // Calculate hours, minutes, and seconds
    let remainingHours = Math.floor(remainingTimeS / 3600);
    let remainingMinutes = Math.floor((remainingTimeS % 3600) / 60);
    let remainingSeconds = remainingTimeS % 60;

    // Ensure two-digit formatting
    if (remainingSeconds < 10) {
        remainingSeconds = "0" + remainingSeconds;
    }
    if (remainingMinutes < 10) {
        remainingMinutes = "0" + remainingMinutes;
    }
    if (remainingHours < 10) {
        remainingHours = "0" + remainingHours;
    }

    return {
        hours: remainingHours,
        minutes: remainingMinutes,
        seconds: remainingSeconds
    };
}

function calculateRemainingTime_DHMS(startTimeMs, endTimeMs) {
    const currentTimeMs = Date.now(); // Current time in milliseconds

    // Ensure endTimeMs is in the future relative to startTimeMs
    if (endTimeMs <= startTimeMs) {
        return {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        };
    }

    // Calculate remaining time in milliseconds
    const remainingTimeMs = endTimeMs - currentTimeMs;

    if (remainingTimeMs <= 0) {
        return {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        };
    }

    // Convert remaining time to seconds
    const remainingTimeS = Math.floor(remainingTimeMs / 1000);

    // Calculate days, hours, minutes, and seconds
    let remainingDays = Math.floor(remainingTimeS / 86400);
    let remainingHours = Math.floor((remainingTimeS % 86400) / 3600);
    let remainingMinutes = Math.floor((remainingTimeS % 3600) / 60);
    let remainingSeconds = remainingTimeS % 60;

    // Ensure two-digit formatting
    if (remainingSeconds < 10) {
        remainingSeconds = "0" + remainingSeconds;
    }
    if (remainingMinutes < 10) {
        remainingMinutes = "0" + remainingMinutes;
    }
    if (remainingHours < 10) {
        remainingHours = "0" + remainingHours;
    }
    if (remainingDays < 10) {
        remainingDays = "0" + remainingDays;
    }

    return {
        days: remainingDays,
        hours: remainingHours,
        minutes: remainingMinutes,
        seconds: remainingSeconds
    };
}

module.exports = { 
    calculateElapsedTime_HMS, 
    calculateRemainingTime_HMS, 
    calculateRemainingTime_DHMS };