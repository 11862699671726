import React from 'react';
import { Box, Grid, Typography, CircularProgress } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { keyframes } from '@mui/system';
import loadingLogo from '../../assets/images/logo/loading-logo.png';
import LoadingRoadmap from './LoadingRoadmap';

// Inspired by the former CircularProgress.js
function GradientCircularProgress() {
    return (
      <React.Fragment>
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#2805CF" />
              <stop offset="100%" stopColor="#1CB5E0" />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress
          variant="indeterminate"
          disableShrink
          size={125}
          thickness={7}
          sx={{ 'svg circle': { stroke: 'url(#my_gradient)' }, animationDuration: '1900ms', 'position': 'absolute', left: '40vw', top: '125vw' }} />
      </React.Fragment>
    );
}

function LoadingScreen(props) {
    const { loadedAppData, whitelistGradient = false } = props;

    return (
        <Grid item display={!loadedAppData ? 'block' : 'none'} xs={12} width="105vw" height="105vh" position="absolute" zIndex={9999} sx={{ 
          background: whitelistGradient ? 
            'linear-gradient(356deg, #0e002f, #0076e0a3)' : 
            'linear-gradient(180deg, rgb(0 0 0 / 16%) 0%, rgb(0 6 32) 100%)',
          backdropFilter: 'blur(10px) drop-shadow(2px 4px 6px black)'}}>
            <Typography variant="h4" fontSize="7vw" fontFamily="Poppins-Bold, sans-serif" fontWeight="bold" color="#BEB7FF" position="relative" top="10vh" left="20vw">
               Wonka Roadmap
            </Typography>
             <LoadingRoadmap />
             <GradientCircularProgress />
             <Box component="img" loading="lazy" alt="Loading Logo" src={loadingLogo} sx={{ position: 'absolute', top: '65vh', left: '25vw', width: '60vw', height: 'auto', opacity: '0.95', filter: 'saturate(0.3)' }} />
          </Grid>
    );
};

export default LoadingScreen