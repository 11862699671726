import React, { useState, useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import TaskList from './Task/TaskList';
import ButtonTabs from './Tabs/Tabs';
import ConfirmPopup from './Task/ConfirmPopup';
import Confetti from './Confetti/Confetti';
import axios from 'axios';
import confetti from '../assets/images/gifs/confetti.gif';
import cloud from '../assets/images/cloud-bg.png';
import bouncingWillyWonk from '../assets/images/gifs/bouncing-on-cloud-willy-wonka.gif';


const PopupImage = styled('img')({
    width: '80vw',
    height: '30vw',
    position: 'absolute',
    top: '7vh',
    left: '50%',
    transform: 'translateX(-50%)',
});

function TaskPage(props) {
  const {userData, openConfetti, setOpenConfetti, telApp, ENDPOINT} = props;
  const [tasks, setTasks] = useState(null);
  const [activeTask, setActiveTask] = useState(null);
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [stopFetchingTasks, setStopFetchingTasks] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
     if (!stopFetchingTasks) {
       const getTasks = async() => {
           const response = await axios.get(`${ENDPOINT}/get-tasks`);

           const mappedTasks = Object.values(response.data.tasks).map((task) => {
               return task
           })

           // Sort by Telegram first, X second, Youtube third and Farcaster last
           mappedTasks.sort((a, b) => {
               if (a.type === 'Telegram' && b.type !== 'Telegram') {
                   return -1;
               } else if (a.type !== 'Telegram' && b.type === 'Telegram') {
                   return 1;
               } else if (a.type === 'X' && b.type !== 'X') {
                   return -1;
               } else if (a.type !== 'X' && b.type === 'X') {
                   return 1;
               } else if (a.type === 'Youtube' && b.type !== 'Youtube') {
                   return -1;
               } else if (a.type !== 'Youtube' && b.type === 'Youtube') {
                   return 1;
               } else if (a.type === 'Farcaster' && b.type !== 'Farcaster') {
                   return -1;
               } else if (a.type !== 'Farcaster' && b.type === 'Farcaster') {
                   return 1;
               } else {
                   return 0;
               }
           })
           

           setTasks(mappedTasks);
       }
       
       const interval = setInterval(() => {
           getTasks();
       }, 2000);

       return () => clearInterval(interval);
      }
   }, [stopFetchingTasks]);

  useEffect(() => {
       if (userData) {
           setUserId(userData.id);
       }
  }, [userData]);

  return (
    <Box sx={{ height: '100vh', minHeight: '100vh', display: 'flex'}}>
        <Grid container justify="center" sx={{ height: '100vh', flexDirection: 'row', flexWrap: 'wrap', alignContent: 'space-between', justifyContent: 'space-around', alignItems: 'center'   }}>          
            {/* Top header */}
            <Grid item xs={12} display="flex" flexDirection={'column'} justifyContent="center" alignItems="center" height='10vh' sx={{ mb: 8, mt: 10 }}>
                <Typography variant="h5" fontFamily="Poppins-Bold, sans-serif" fontWeight="bold" zIndex={1} sx={{ color: 'white' }}>Complete Tasks</Typography>
                <Typography variant="h5" fontFamily="Poppins-Bold, sans-serif" fontWeight="bold" zIndex={1} sx={{ color: 'white' }}>Earn Points!</Typography>
                <PopupImage src={cloud} alt="Popup Image" loading='eager' />
                <Box component="img" loading="lazy" src={'https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Activity/Mirror%20Ball.webp'} alt="Mirror Ball" sx={{ width: '80px', position: 'absolute', top: '16vh', right: '11vw' }} />
            </Grid>

            {/* Here there are some task lists, like join tg group, add bot to group, etc  */}
            <TaskList tasks={tasks} userId={userId} telApp={telApp} setActiveTask={setActiveTask} setOpenConfirmPopup={setOpenConfirmPopup} ENDPOINT={ENDPOINT}/>
    
            {/* Confirm popup */}
            <ConfirmPopup task={tasks? tasks[activeTask -1] : null} userId={userId} telApp={telApp} open={openConfirmPopup} setOpenConfetti={setOpenConfetti} setStopFetchingTasks={setStopFetchingTasks} onClose={() => setOpenConfirmPopup(false)} ENDPOINT={ENDPOINT} />

           {/* Confetti popup */}
            <Confetti openConfetti={openConfetti} x={0.5} y={0.85} />

            {/* Bottom footer */}
            <ButtonTabs tabValue={2}/>
        </Grid>
    </Box>
  )
}

export default TaskPage;