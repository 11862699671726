import React, { useEffect } from 'react';
import WonkaHome from './components/Home';
import TaskPage from './components/Task';
import RewardsPage from './components/Rewards';
import ReferralPage from './components/Referral';
import WaitlistPage from './components/Waitlist';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Routes, Route } from "react-router-dom";
import { app, analytics } from './firebase'; // Import the Firebase configuration
import axios from 'axios';

const telApp = window.Telegram.WebApp;

const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1280,
    },
  },
});

const ENDPOINT = 'https://app.wonka.tips/api';

function App() {
  const [isTelegramMiniApp, setIsTelegramMiniApp] = React.useState(false);
  const [userData, setUserData] = React.useState(null);
  const [profileUrl, setProfileUrl] = React.useState(null);
  const [userPlatform, setUserPlatform] = React.useState(null);
  const [openConfetti, setOpenConfetti] = React.useState(false);
  const [startAppId, setStartAppId] = React.useState(null);

  const init = () => {
    //  change some initial properties
    telApp.ready();    
    telApp.expand();
    telApp.setHeaderColor('#ffffff');

    setUserPlatform(telApp.platform);
    setStartAppId(Number(telApp.initDataUnsafe.start_param));

    var search = telApp.initData;
    var data = null;
    if (search !== null && search !== '') {
       var converted = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) {
         return key === "" ? value : decodeURIComponent(value);
       });
       var data = JSON.parse(converted.user);

      //  if has not username, alert user to add username and then back
       if (!data.username) {
         telApp.showAlert('Please add username to your telegram account then back!');
         telApp.close();
       }
   } 
   else {
       var data = {
         "id": 1887509957,
         "first_name": "Its Justin",
         "last_name": "",
         "username": "P2P_JS",
         "language_code": "en",
         "is_premium": true,
         "allows_write_to_pm": true
     }
   }
    if (data !== null) {
      setUserData(data)
      setIsTelegramMiniApp(true);
    } else {
       setIsTelegramMiniApp(false);
    }
 }

 const getUserProfile = async () => {
  const getFileId = await axios.get(`https://api.telegram.org/bot7388150071:AAG2LyfA352GuQE5c6KQRlKVxS7FcG01pv0/getUserProfilePhotos?user_id=${userData.id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  })
  const fileId = getFileId.data.result.photos[0][2].file_id
  const getFilePath = await axios.get(`https://api.telegram.org/bot7388150071:AAG2LyfA352GuQE5c6KQRlKVxS7FcG01pv0/getFile?file_id=${fileId}`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  })
  const filePath = getFilePath.data.result.file_path;
  const profilePicUrl = `https://api.telegram.org/file/bot7388150071:AAG2LyfA352GuQE5c6KQRlKVxS7FcG01pv0/${filePath}`


  //  Send profile picture url to backend
  await axios.post(`${ENDPOINT}/upload-profile`, {
    userId: userData.id,
    url: profilePicUrl
  }).then((response) => {
    console.log(response);
  }).catch((error) => {
    console.log(error);
  })
   
   setProfileUrl(profilePicUrl)
 }

  const handleSignup = async () => {
    await axios.post(`${ENDPOINT}/signup`, {
      userId: userData.id,
      username: userData.username,
      firstname: userData.first_name,
      lastname: userData.last_name,
      startAppId: startAppId
    }).then((response) => {
      console.log(response);
    }).catch((error) => {
      console.log(error);
    })
  }

  useEffect(() => {
    init();

    localStorage.setItem('appLoaded', false)
  }, []);

  useEffect(() => {
    if (userData !== null) {
      getUserProfile();
      handleSignup();
    }
  }, [userData]);

  return (
    <ThemeProvider theme={theme}>
      <Routes>
         
          {/* Home Page */}
         <Route path="/under-construction" element={<WonkaHome 
             theme={theme} userData={userData}
             profileUrl={profileUrl} userPlatform={userPlatform} 
             openConfetti={openConfetti} setOpenConfetti={setOpenConfetti}
             telApp={telApp} ENDPOINT={ENDPOINT} />} />
         
          {/* Task Page */}
         <Route path="/task" element={<TaskPage 
            theme={theme} userData={userData} 
            openConfetti={openConfetti} setOpenConfetti={setOpenConfetti}
            telApp={telApp} ENDPOINT={ENDPOINT} />} />

          {/* Rewards Page */}
         <Route path="/rewards" element={<RewardsPage
            theme={theme} userData={userData}
            openConfetti={openConfetti} setOpenConfetti={setOpenConfetti}
            telApp={telApp} ENDPOINT={ENDPOINT} />} />

            {/* Referral Page */}
         <Route path="/referrals" element={<ReferralPage
            theme={theme} userData={userData}
            openConfetti={openConfetti} setOpenConfetti={setOpenConfetti}
            telApp={telApp} ENDPOINT={ENDPOINT} />} />
   
          {/* Waitlist Pages */}
         <Route path="/waitlist" element={<WaitlistPage
            theme={theme} userData={userData}
            openConfetti={openConfetti} setOpenConfetti={setOpenConfetti}
            telApp={telApp} ENDPOINT={ENDPOINT} />} />

        <Route path="/" element={<WaitlistPage
            theme={theme} userData={userData}
            openConfetti={openConfetti} setOpenConfetti={setOpenConfetti}
            telApp={telApp} ENDPOINT={ENDPOINT} />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;