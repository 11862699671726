import React, { useState, useEffect } from 'react';
import { 
  Box, Grid, Typography, LinearProgress,
  Button, Avatar } from '@mui/material';
import eatingGif from '../assets/images/gifs/eat-chokolate-2.gif';
import chocolateIcon from '../assets/images/logo/chocolate.png';
import CakeIcon from '@mui/icons-material/Cake';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import floatingWillyWonka from '../assets/images/gifs/floating-willy-wonka.gif';
import bgg from '../assets/images/cloud-bg.png';
import wonkaLogo from '../assets/images/logo/wonka.png';
import { calculateRemainingTime_HMS } from '../utils/Time'; 
import LoadingScreen from './Home/LoadingScreen';
import { keyframes } from '@mui/system';
import SendTipPopup from './Home/SendTipPopup';
import HistoryPopup from './Home/HistoryPopup';
import LeaguesPopup from './Home/LeaguesPopup';
import RewardsPopup from './Home/RewardsPopup';
import BottomTabs from './Tabs/Tabs';
import Confetti from './Confetti/Confetti';
import axios from 'axios';

// Keyframes for animations
const chocolateAnimation = keyframes`
  0% {
    transform: rotate(0deg) translateY(0px);
  }
  25% {
    transform: rotate(20deg) translateY(-20px) translateX(10px);
  }
  50% {
    transform: rotate(15deg) translateY(-5px) translateX(5px);
  }
  75% {
    transform: rotate(20deg) translateY(5px) translateX(5px);
  }
  100% {
    transform: rotate(0deg) translateY(0px) translateX(0px);
  }
`;

const banners = [
  { url: 'https://specials.manoramaonline.com/Mobile/2022/Daily-Quiz/images/fb-share.jpg', link: "https://google.com" },
  // { url: 'https://procorda.com/wp-content/uploads/2020/05/Willy-Wonka-post-banner-uai-1440x359.jpg', link: "https://google.com" },
  { url: 'https://elfman.cinemusic.net/films/charlieandthechocolatefactory/charlie_factory.jpg', link: "https://google.com" },
  { url: 'https://storage.prompt-hunt.workers.dev/clel94x480001l1083vvqhenu_4', link: "https://google.com" },
];

function WonkaHome(props) {
  const { userData, profileUrl, userPlatform, openConfetti, setOpenConfetti, telApp, ENDPOINT } = props;
  const [currentBanner, setCurrentBanner] = useState(0);
  const [userProfileInfo, setUserProfileInfo] = useState(null);
  const [remainingNextFarming, setRemainingNextFarming] = useState(null);
  const [isActivatingFarmingButton, setIsActivatingFarmingButton] = useState(false);
  const [farmingProgressPercent, setFarmingProgressPercent] = useState(0);
  const [loadedAppData, setLoadedAppData] = useState(false);
  const [openLeaguesPopup, setOpenLeaguesPopup] = useState(false);
  const [openSendTipPopup, setOpenSendTipPopup] = useState(false);
  const [openHistoryPopup, setOpenHistoryPopup] = useState(false);
  const [openRewardsPopup, setOpenRewardsPopup] = useState(false);
  const [stopFetchingProfileInfo, setStopFetchingProfileInfo] = useState(false);

  const handleFarming = async () => {
    if (!userData || userData.isFarmingActive || isActivatingFarmingButton) return;
    // Disable button
    setIsActivatingFarmingButton(true);

    // Activate farming
    await axios.post(`${ENDPOINT}/activate-farm`, { userId: userData.id })
    .then((response) => {
        setOpenConfetti(true);
    }).catch((error) => {
       console.log(error);
    }).finally(() => {
        setTimeout(() => {
          setOpenConfetti(false);
        }, 3000);
    })

    setTimeout(() => {
      setIsActivatingFarmingButton(false);
    }, 3000);
  };

  // Fetch user profile info
  useEffect(() => {
    if (userData && !stopFetchingProfileInfo) {
      const fetchProfileInfo = async () => {
        try {
          const response = await axios.get(`${ENDPOINT}/user/${userData.id}`);
          setUserProfileInfo(response.data);
        } catch (error) {
          console.error(error);
        }
      }
          
      // make an interval to fetch profile info every 10 seconds
      const interval = setInterval(() => {
        fetchProfileInfo();
      }, 1000); 
    
      // clear the interval when the component unmounts
      return () => clearInterval(interval);
    }
  }, [userData, stopFetchingProfileInfo]);

  // Set Stop Fetching Profile Info
  useEffect(() => {
     if (openHistoryPopup || openSendTipPopup || openRewardsPopup) setStopFetchingProfileInfo(true);
     else setStopFetchingProfileInfo(false);
  }, [openHistoryPopup, openSendTipPopup, openRewardsPopup]);

  // Fetch next farming time
  useEffect(() => {
    let appLoaded = localStorage.getItem('appLoaded') || false;
    let loadingTime = appLoaded ? 100 : 3000;

    if (userProfileInfo) {
      // Check if app is loaded
      if (!loadedAppData) {
        setTimeout(() => {
          setLoadedAppData(true);
          localStorage.setItem('appLoaded', true);
        }, loadingTime);
      }
      
      // Calculate next farming time
      if (userProfileInfo.isFarmingActive) {
            const startTimeMs = Date.parse(userProfileInfo?.farmStartedAt); // Example start time in milliseconds
            const farmingIntervalMs = 6 * 60 * 60 * 1000; // 6 hours in milliseconds
            const nextFarmingTimeMs = startTimeMs + farmingIntervalMs; // Example future time in milliseconds

            const remainingTime = calculateRemainingTime_HMS(startTimeMs, nextFarmingTimeMs);
            // Get % of progress
            const passedTimePercentage = 100 - Math.round(100 * (nextFarmingTimeMs - Date.now()) / farmingIntervalMs);
            setFarmingProgressPercent(passedTimePercentage);
            setRemainingNextFarming(`${remainingTime.hours}H ${remainingTime.minutes}M ${remainingTime.seconds}S`);
      } 

      // Check FarmingRewards 
      if (userProfileInfo.requiresRewardClaim) {
        setOpenRewardsPopup(true);
      }    
  }
}, [userProfileInfo]);

  return (
      <Box sx={{ height: '100vh', minHeight: '100vh', display: 'flex'}}>
        <Grid container spacing={2} justify="center" sx={{ height: '100vh', flexDirection: 'row', flexWrap: 'wrap', alignContent: 'space-between', justifyContent: 'space-around', alignItems: 'center' }}>          
          {/* Loading Screen */}
          <LoadingScreen loadedAppData={loadedAppData} />
        
          {/* Header Section */}
          <Grid item xs={12} width="105vw" position="relative" left="2.5vw" top="2.5vh">
             {/* Profile Card */}
              <Grid container spacing={1} alignItems="center" ml={0.5} mr={0} sx={{ backgroundColor: 'transparent', width: '95vw', borderRadius: '20px', pb: '8px' }}>
                <Grid item xs={3}>
                  <Avatar src={profileUrl} alt="Profile Photo" sx={{ width: 75, height: 75, borderRadius: '50%' }} />
                </Grid>
                <Grid item xs={9} mr={"auto"} ml={0.5}>
                  <Typography variant="h6" fontFamily="Sora, sans-serif" fontWeight="bold" color="#BEB7FF" position="relative" top="15px">
                    Hello, {userData?.first_name ? userData.first_name.length > 10 ? userData.first_name.slice(0, 10) + '...' : userData.first_name : 'Loading..' || 'Anonymous'}
                    {userData?.last_name || ''} {userData?.is_premium ? '🍫' : ''}
                  </Typography>
                  <Typography variant="h6" align="center" color="#BEB7FF" fontFamily="Sora, sans-serif" fontSize="0.85rem" fontWeight="bold" py={1} sx={{textAlign: 'left'}}>
                   Welcome to wonka.tips!
                </Typography>
                </Grid>
              </Grid>

             {/* Daily Allowance */}
              <Grid container spacing={1} display="flex" flexWrap="nowrap" flexDirection='column' justifyContent="flex-start" alignItems="center" my={0} p={0} ml={0} mr={0} height={"18vh"} sx={{ background: `url(${bgg})`, backgroundSize: '94vw 17.5vh', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', width: '95vw', borderRadius: '20px' }}>
                <Typography variant="h6" align="center" fontFamily="Poppins-Bold, sans-serif" fontSize="1.35rem" color="#fff" position="relative" top="23px">
                  Daily Allowance 
                </Typography>
                {/* make an box as Image */}
                <Box component="img" loading="lazy" src={'https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Food%20and%20Drink/Lollipop.webp'} alt="Lollipop" sx={{ width: '25vw', position: 'absolute', bottom: '11vh', left: '70vw', rotate: '70deg' }} />
                <Typography variant="h6" align="center" fontFamily="Poppins-Bold, sans-serif" fontSize="2.25rem" color="#fff" position="relative" top="20px" left="2vw">
                        {userProfileInfo?.allowance || 'Loading..'} <span style={{fontSize: '1.15rem'}}>/ {userProfileInfo?.initialAllowance || 'Loading..'} </span>
                </Typography>
                <Box component="img" loading="lazy" src={wonkaLogo} alt="TipHat" sx={{ width: '15vw', position: 'absolute', bottom: '9.5vh', right: '36.5vw', transform: 'translateX(-50%)', background: '#ffffff82', borderRadius: '50%', padding: '0px', filter: 'hue-rotate(200deg) drop-shadow(0px 0px 18px dodgerblue)' }} />
              </Grid>

              {/* Points and League */}
              <Grid container spacing={1} display="flex" flexDirection='row' justifyContent="center" alignItems="center" position="relative" right="3.5vw" bottom="2.5vh" top="0.5vh" my={0} ml={0} mr={0} p={0} height={"15vh"}>
                  <Grid container display="flex" flexDirection='column' justifyContent="flex-start" alignItems="center" my={0} ml={0} mr={0} height={"15vh"} sx={{ background: `url(${bgg})`, backgroundSize: '180px 90px', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', width: '46vw', borderRadius: '20px' }}>
                    <Typography variant="h6" align="center" fontFamily="Poppins-Bold, sans-serif" fontSize="1.05rem" color="#fff" position="relative" top="26px">
                       Points
                    </Typography>
                    <Typography variant="h6" align="center" fontFamily="Poppins-Bold, sans-serif" fontSize="1.65rem" color="#fff" position="relative" top="22px">
                       {Number(userProfileInfo?.points).toFixed(0) || 'Loading..'} 
                    </Typography>
                       <AutoAwesomeIcon sx={{ color: '#fff', fontSize: '2.15rem', position: 'absolute', top: '12vh', background: '#ffffff82', borderRadius: '50%', padding: '10px', filter: 'drop-shadow(0px 0px 18px dodgerblue)' }} />
                  </Grid>
                  <Grid container onClick={() => setOpenLeaguesPopup(true)} display="flex" flexDirection='column' justifyContent="flex-start" alignItems="center" my={0} ml={0} mr={0} height={"15vh"} sx={{ background: `url(${bgg})`, backgroundSize: '180px 90px', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', width: '46vw', borderRadius: '20px' }}>
                    <Typography variant="h6" align="center" fontFamily="Poppins-Bold, sans-serif" fontSize="1.05rem" color="#fff" position="relative" top="26px">
                      League {userProfileInfo?.leagueIndex}
                    </Typography>
                    <Typography variant="h6" align="center" fontFamily="Poppins-Bold, sans-serif" fontSize="1.45rem" fontWeight="bold" color="#fff" position="relative" top="23px">
                       {userProfileInfo?.league || 'Loading..'} 
                    </Typography>
                    <CakeIcon sx={{ color: '#fff', fontSize: '2.15rem', position: 'absolute', top: '12vh', background: '#ffffff82', borderRadius: '50%', padding: '10px', filter: 'drop-shadow(0px 0px 18px dodgerblue)' }} />
                  </Grid>
              </Grid>
          </Grid>

            {/* Banner */}
          {/* <Grid item xs={12} position="relative" bottom="3vh" mx={'auto'}>
              <Box sx={{width: '90vw',height: '15vh',display: 'flex',justifyContent: 'center',alignItems: 'center',backgroundImage: `url(${banners[currentBanner].url})`,backgroundSize: 'cover',backgroundPosition: 'center',borderRadius: '15px',transition: 'background-image 0.5s linear'}}>
                  <IconButton onClick={handlePrevious}>
                    <ArrowBackIcon />
                  </IconButton>
                  <IconButton onClick={handleNext}>
                    <ArrowForwardIcon />
                  </IconButton>
                  <a href={banners[currentBanner].link} target="_blank" rel="noopener noreferrer" style={{ width: '100%', height: '100%', display: 'block' }}></a>
              </Box>
          </Grid>           */}

          {/* Send and History and Farming Buttons */}
          <Grid item xs={12} mb={0.75} position="relative" bottom="11vh" mx={'auto'}>
            <Grid container display="flex" flexDirection='row-reverse' justifyContent="space-evenly" alignItems="center" my={0} ml={0} mr={0} height={"10vh"}>
                <Button variant="contained" onClick={() => setOpenSendTipPopup(true)} sx={{ width: '44vw', ml: 0.5, height: '7vh', position: 'relative', display: 'flex', justifyContent: 'space-around', alignItems: 'center', borderRadius: '20px', background: 'royalblue', color: 'white', fontFamily: 'Sora, sans-serif', fontWeight: 800 }}>
                  Send Tip
                </Button>
                <Button variant="contained" onClick={() => setOpenHistoryPopup(true)} sx={{ width: '44vw', mr: 0.5, height: '7vh', position: 'relative', display: 'flex', justifyContent: 'space-around', alignItems: 'center', borderRadius: '20px', background: 'royalblue', color: 'white', fontFamily: 'Sora, sans-serif', fontWeight: 800 }}>
                  History
                </Button> 
              </Grid>
           <Grid container display="flex" flexDirection='row' justifyContent="flex-start" alignItems="center" my={0} ml={0} mr={0} height={"10vh"}>
                <Button variant="contained" onClick={handleFarming} sx={{ width: '90vw', height: '10vh', position: 'relative', display: 'flex', justifyContent: 'space-around', alignItems: 'center', borderRadius: '25px', background: 'royalblue', color: 'white', fontFamily: 'Sora, sans-serif', fontWeight: 800 }}>
                  {userProfileInfo?.isFarmingActive? 
                      <Box
                          component="img"
                          src={'https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Food%20and%20Drink/Chocolate%20Bar.webp'}
                          alt="GIF Icon"
                          position="absolute" 
                          left={-2}
                          sx={{ width: 100, height: 100, verticalAlign: 'middle', pl: 1, animation: `${chocolateAnimation} 2s infinite ease` }} // Adjust size as needed
                        /> :
                      <Box
                        component="img"
                        src={chocolateIcon}
                        alt="GIF Icon"
                        position="absolute" 
                        right={'60vw'}
                        sx={{ width: 120, height: 120, verticalAlign: 'middle', pl: 1, animation: `${chocolateAnimation} 2s infinite ease` }} // Adjust size as needed
                      />
                  }
                  <Typography variant="h6" fontFamily="Poppins-Bold, sans-serif" fontWeight="200" fontSize={"21px"} zIndex={2} sx={{ px: 1 }}>
                      {isActivatingFarmingButton? "Activating..." : userProfileInfo?.isFarmingActive? "Farming..." : "Start Farming"}
                  </Typography>
                  {userProfileInfo?.isFarmingActive &&
                    <Typography variant="h6" fontFamily="Poppins-Bold, sans-serif" fontWeight="800" fontSize={"15px"}>{remainingNextFarming || "00:00:00"}</Typography> 
                  }
                </Button>
              </Grid>
                {userProfileInfo?.isFarmingActive &&
                  <LinearProgress 
                      variant="determinate" 
                      value={farmingProgressPercent} 
                      sx={{ transform: isActivatingFarmingButton? 'translateX(-115%)' : 'translateX(0%)', transition: 'all 0.25s linear', position: 'relative', bottom: '0px', width: '85vw', height: '1.5vh', borderRadius: '6px', mt: 1, mx: 'auto', '& .MuiLinearProgress-bar': { background: 'royalblue'} }} />
                }
          </Grid>
        
          {/* Bottom Tabs */}
          <BottomTabs tabValue={1} />

          {/* Leagues Popup */}
          <LeaguesPopup userId={userProfileInfo?.userId} userLeagueIndex={userProfileInfo?.leagueIndex} open={openLeaguesPopup} onClose={() => setOpenLeaguesPopup(false)} ENDPOINT={ENDPOINT}/>

          {/* Send Tip Popup */}
          <SendTipPopup userId={userProfileInfo?.userId} remainingAllowance={userProfileInfo?.allowance} open={openSendTipPopup} onClose={() => setOpenSendTipPopup(false)} telApp={telApp} ENDPOINT={ENDPOINT}/>
       
          {/* History Popup */}
          <HistoryPopup userId={userProfileInfo?.userId} open={openHistoryPopup} onClose={() => setOpenHistoryPopup(false)} ENDPOINT={ENDPOINT}/>

          {/*  Rewards Popup */}
          <RewardsPopup open={openRewardsPopup} onClose={() => setOpenRewardsPopup(false)} setOpenConfetti={setOpenConfetti} telApp={telApp} ENDPOINT={ENDPOINT} />

          {/* Confetti */}
          <Confetti openConfetti={openConfetti} />
        </Grid>
      </Box>
  );
}

export default WonkaHome;