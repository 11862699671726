import React from 'react';
import { Box, Grid, Typography, IconButton } from '@mui/material';
import { CopyAllSharp } from '@mui/icons-material';

function ShareLink(props) {
  const { inviteLink, shareMessageLink, copyToClipboard, telApp } = props;

  const shareLink = () => {
    // Open the link in a new tab
    window.open(shareMessageLink, '_blank');
  }

  return (
    <Box display="flex" flexDirection={'row'} justifyContent="center" alignItems="center" position={'relative'} bottom={'14vh'} width={'93vw'} height='10vh' sx={{ alignItems: 'center', mb: 1, background:'dodgerblue', borderRadius: '20px',  }}>
        <Grid onClick={shareLink} item xs={12} display="flex" flexDirection={'column'} justifyContent="center" alignItems="center" mx={'auto'} mb={2} pt={2} pr={4}>
            <Typography variant="h6" fontFamily="Sora, sans-serif" fontWeight="bold" zIndex={1} width={'100%'} sx={{ color: 'white' }}>Share Invite Link</Typography>
         </Grid>
         <Grid item display="flex" flexDirection={'row'} justifyContent="center" alignItems="center" width={'10vw'} mr={2}>
          <IconButton onClick={copyToClipboard}>
            <CopyAllSharp sx={{ color: 'white' }} />
          </IconButton>
        </Grid>
    </Box>
  )
}

export default ShareLink