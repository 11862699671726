import React from 'react';
import { Button, Typography, Box } from '@mui/material';
import ProgressBar from './ProgressBar';

const Page1 = ({ nextPage, backgroundSource }) => {
  return (
    <Box
      sx={{
        width: '100vw', 
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        backgroundImage: `url('${backgroundSource}')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
      }}
    >
      <ProgressBar step={1} />
      <Typography
        variant="h6"
        align="center"
        fontFamily="Sora, sans-serif"
        fontWeight="bold"
        gutterBottom
        sx={{ color: 'white', backgroundColor: 'rgba(0,0,0,0.5)', borderRadius: '20px', width: '85vw', mx: 'auto', py: 2, px: 1 }}
      >
         Welcome to Wonka.tips! <br /> Just a few steps to obtain the Early Role
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={nextPage}
        sx={{ mx: 'auto', width: '85vw', mb: 2, py: 1, fontSize: '1.15rem', fontFamily: 'Sora, sans-serif', fontWeight: 'bold', borderRadius: '20px', textTransform: 'capitalize' }}
      >
        Start
      </Button>
    </Box>
  );
};

export default Page1;
