import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Typography, IconButton, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import axios from 'axios';

const PopupContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  width: '96%',
  height: '30vh',
  background: 'linear-gradient(184deg, #698dff, transparent)',
  backdropFilter: 'blur(10px)',
  borderTopLeftRadius: '16px',
  borderTopRightRadius: '25px',
  boxShadow: theme.shadows[5],
  transform: 'translateY(100%)',
  transition: 'transform 0.5s ease-in-out',
  marginLeft: 'auto',
  marginRight: 'auto',
//   change color of everything to white
  '*': {
    color: 'white',
  },
  zIndex: 9999,
}));
 
const StyledTabButton = styled(Button)(({ theme }) => ({
    fontSize: '18px', 
    width: '75vw', 
    height: '8vh', 
    borderRadius: '20px', 
    background: 'floralwhite', 
    color: 'black !important', 
    fontFamily: 'Sora, sans-serif', 
    fontWeight: 600, 
    textTransform: 'Capitalize',
    marginTop: '0.5vh',
    '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
    },
}));

function ConfirmTaskPopup(props) {
  const { 
    task, userId, telApp, open, 
    onClose, setOpenConfetti, ENDPOINT,
    setStopFetchingTasks } = props;

  const joinButtonTitle = (type) => {
    switch (type) {
      case 'Telegram':
        return 'Join';
      case 'X':
        return 'Follow';
      case 'Youtube':
        return 'Subscribe';
      case 'Farcaster':
        return 'Follow';
    }
  }

  const handleSubButton = async() => {
     window.open(task.link, '_blank');
     if (task.type === 'Telegram') {
      localStorage.setItem('subClickCount', 2);
     } else {
       let subClickCount = localStorage.getItem('subClickCount') || 0;
       localStorage.setItem('subClickCount', Number(subClickCount) + 1);
     }
  }

  const handleCheckButton = async() => {
    const subClickCount = localStorage.getItem('subClickCount');

    setStopFetchingTasks(true);

    if (subClickCount % 2 === 0) {
      await axios.get(`${ENDPOINT}/check-task`, { params: { taskId: task.taskId, userId: userId } })
      .then((response) => {
        setOpenConfetti(true);
        onClose();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setTimeout(() => {
          setStopFetchingTasks(false);
        }, 500);
        setTimeout(() => {
          setOpenConfetti(false);
        }, 3000);
      })
    } else {
      telApp?.showAlert('Please complete task to claim reward.');
    }
  }

  return (
    // when touched outside popup will close
    <PopupContainer sx={{ transform: open ? 'translateY(0)' : 'translateY(107%)'}} onClose={onClose}>
       {/* black overlay */}
       <Box display={open ? 'block' : 'none'} width="200vw" height="210vh" position="absolute" top={0} left={0} right={0} bottom={0} bgcolor="black" sx={{ opacity: '0.5', backdropFilter: 'blur(10px)', transform: 'translate(-50%, -50%)' }} />

       {/* Close button */}
      <Box display="flex" justifyContent="flex-end" p={2} position="absolute" right={0}>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box display="flex " flexDirection="column" justifyContent="center">
         <Typography variant="h5" position={"relative"} top={"25px"} fontFamily={"Sora"} fontSize={"20px"} ml={2} sx={{ width: 'max-content' }}>{task? task.title.length > 23 ? `${task.title.slice(0, 23)}...` : task.title : ''}</Typography>
      </Box>
        {/* Show received users */}
         <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="100%" position="relative" top="6vh">
            <StyledTabButton onClick={handleSubButton}>
                {joinButtonTitle(task? task.type : '')}
           </StyledTabButton>
           <StyledTabButton onClick={handleCheckButton}>
               Check Task
           </StyledTabButton>
        </Box>
    </PopupContainer>
  );
};

export default ConfirmTaskPopup;