// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAEZ2gG2sPVHYYiB76Ai6lLDF3AZon46Og",
    authDomain: "wonka-tips.firebaseapp.com",
    databaseURL: "https://wonka-tips-default-rtdb.firebaseio.com",
    projectId: "wonka-tips",
    storageBucket: "wonka-tips.appspot.com",
    messagingSenderId: "684659388286",
    appId: "1:684659388286:web:27749a4be106338c268d2f",
    measurementId: "G-FDHBYJJ4EN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics };
