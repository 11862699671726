import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, Avatar,
  List, ListItem, ListItemText, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Leaf from '../../assets/images/leaf-right.png';
import TipHat from '../../assets/images/home-elements/tip-hat.png';
import { styled } from '@mui/system';
import axios from 'axios';

const PopupContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  width: '96%',
  height: '90vh',
//   makeit to linear gradient
  background: 'linear-gradient(180deg, rgb(102 140 252 / 93%) -9%, rgb(0 0 0 / 54%) 100%)',
  backdropFilter: 'blur(10px)',
  borderTopLeftRadius: '16px',
  borderTopRightRadius: '25px',
  boxShadow: theme.shadows[5],
  transform: 'translateY(100%)',
  transition: 'transform 0.5s ease-in-out',
  marginLeft: 'auto',
  marginRight: 'auto',
//   change color of everything to white
  '*': {
    color: 'white',
  },
  zIndex: 999,
}));
 
const PopupImage = styled('img')({
  width: '70px',
  height: '120px',
//   marginTop: '16px',
//   marginBottom: '16px',
  position: 'relative',
  top: '5px',
});

const StyledList = styled(List)(({ theme }) => ({
  '& .MuiListItem-root': {
    padding: '4px 0px',
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  width: '90vw',
  height: '60px',
  borderRadius: '15px',
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  margin: '8px 0px',
}));


function LeaguesPopup(props) {
   const {userId, remainingAllowance,
      open, onClose, userLeagueIndex, ENDPOINT} = props;
   const [leagues, setLeagues] = useState('');
   const [leaguesDataLoading, setLeaguesDataLoading] = useState(false);

   useEffect(() => {
    if (open) {
      setLeaguesDataLoading(true);
      axios
        .get(`${ENDPOINT}/leagues`)
        .then((response) => {
          setLeaguesDataLoading(false);

          const mappedLeagues = Object.entries(response.data).map(([key, value]) => {
            return { id: key, ...value };
          })

          setLeagues(mappedLeagues);
        })
        .catch((error) => {
          setLeaguesDataLoading(false);
          console.log(error);
        });
    }
  }, [open]);

  const leagueIndexIcon = (index) => {
    if (index < 10) {
        if (index % 2 === 0) {
           return `https://img.icons8.com/arcade/64/${index}-circle-c.png`
        } else {
           return `https://img.icons8.com/arcade/64/${index}-circle.png`
        }
  } else {
     return `https://img.icons8.com/arcade/64/${index}.png`
  }
}

  return (
    // when touched outside popup will close
    <PopupContainer sx={{ transform: open ? 'translateY(0)' : 'translateY(107%)', }} onClose={onClose}>
      {/* Close button */}
      <Box display="flex" justifyContent="flex-end" p={2} position="absolute" right={0}>
        <IconButton onClick={onClose} sx={{ zIndex: 999 }}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="center" position='relative' top='15px' >
        <PopupImage src={Leaf} alt="Wonka" loading='lazy' sx={{ width: '55px', height: '70px', transform: 'scaleX(-1)', filter: 'invert(1)' }}/>
        <Typography variant="h5" fontFamily="Sora, sans-serif" fontWeight="bold" sx={{ color: 'white', mt: 2 }}>
             Leagues
        </Typography>
        <PopupImage src={Leaf} alt="Wonka" loading='lazy' sx={{ width: '55px', height: '70px', filter: 'invert(1)' }}/>
      </Box>

        {/* Show all leagues in table */}
      <Box display="flex" justifyContent="center" alignItems="center" width="100%" mt={4}>
          <StyledList dense sx={{ height: '70vh', overflow: 'auto' }}>
            {leagues?
                leagues.map((league, index) => (
                  // Color like dodgerblue
                  <StyledListItem sx={{ backgroundColor: userLeagueIndex === index + 1 ? 'dodgerblue' : 'transparent' }}>
                    <Box component="img" src={TipHat} alt="Wonka" sx={{ display: userLeagueIndex === index + 1 ? 'block' : 'none', position: 'absolute', right: '-2px', bottom: '43px', rotate: '26deg', width: '35px', height: '35px', ml: 1 }} />
                    <Avatar
                      alt={league.id}
                      src={leagueIndexIcon(index + 1)}
                      sx={{ width: 45, height: 45, ml: 1 }}
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'row', ml: 1 }}>
                      <ListItemText primary={`League #${index + 1}`} secondary={league.id}
                          primaryTypographyProps={{ fontFamily: 'Poppins-Medium, sans-serif', color: 'white', fontSize: '14px', fontWeight: '500' }}
                          secondaryTypographyProps={{ fontFamily: 'Poppins-Medium, sans-serif', color: 'white', fontSize: '18px', fontWeight: '600'}}
                          sx={{'& .MuiTypography-root': { color: 'white', fontFamily: 'Sora'}}} />
                      <ListItemText primary={`Point Required: ${league.pointsRequired}`} secondary={`Allowance: ${league.allowance}`} sx={{ position: 'relative', top: '5px', left: '25px', '& .MuiTypography-root': { color: 'white', fontFamily: 'Sora', fontWeight: '300', fontSize: '14px' }}} />
                    </Box>
                  </StyledListItem>
                ))
                : null
            } 
          </StyledList>
       </Box>
    </PopupContainer>
  );
};

export default LeaguesPopup;