import React from 'react';
import { Button, Typography, Box } from '@mui/material';
import ProgressBar from './ProgressBar';
import axios from 'axios';

const Page3 = (props) => {
  const { nextPage, backgroundSource, userData, telApp, ENDPOINT } = props;

  const checkJoiningStatus = async () => {
    try {
      const response = await axios.get(`${ENDPOINT}/whitelist-joining-status`, { params: { userId: userData.id }, headers: { 'authorization': 'Bearer C2Fslbf72-9206-4237-a523-2aa9b24d0519' } });
      if (response.data.status) {
        nextPage();
        localStorage.setItem('waitlistStepsCompleted', true);
      } else {
        telApp.showAlert('Sorry, You have not joined the channel yet!');
      }
    } catch (error) {
      console.error(error);
    }
};

  const joinChannel = () => {
    // Placeholder for joining channel logic
    window.open('https://t.me/wonka_tips', '_blank');
  };

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        backgroundImage: `url('${backgroundSource}')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
      }}
    >
      <ProgressBar step={3} />
      <Typography
        variant="h6"
        align="center"
        fontFamily="Sora, sans-serif"
        fontWeight="bold"
        gutterBottom
        sx={{ color: 'white', backgroundColor: 'rgba(0,0,0,0.5)', borderRadius: '20px', width: '85vw', mx: 'auto', py: 2, px: 1 }}
      >
        Follow the Wonka Announcement Channel
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={joinChannel}
        sx={{ mx: 'auto', width: '85vw', mb: 1, py: 1, fontSize: '1.15rem', fontFamily: 'Sora, sans-serif', fontWeight: 'bold', borderRadius: '20px', textTransform: 'capitalize' }}
      >
        Join Channel
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={checkJoiningStatus}
        sx={{ mx: 'auto', width: '85vw', mb: 2, py: 1, fontSize: '1.15rem', fontFamily: 'Sora, sans-serif', fontWeight: 'bold', borderRadius: '20px', textTransform: 'capitalize' }}
      >
        Next Step
      </Button>
    </Box>
  );
};

export default Page3;
