import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Typography, IconButton, Grid,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Avatar,
  List, ListItem, ListItemText, Divider,
  CircularProgress
 } from '@mui/material';
import CopyIcon from '@mui/icons-material/CopyAllSharp';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import searchingGif from '../../assets/images/gifs/searching.gif';
import wonkaCharacter from '../../assets/images/logo/wonka-character.png';
import { styled } from '@mui/system';
import axios from 'axios';

const PopupContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  width: '96%',
  height: '90vh',
//   makeit to linear gradient
  background: 'linear-gradient(180deg, rgb(102 140 252 / 93%) -9%, rgb(0 0 0 / 54%) 100%)',
  backdropFilter: 'blur(10px)',
  borderTopLeftRadius: '16px',
  borderTopRightRadius: '25px',
  boxShadow: theme.shadows[5],
  transform: 'translateY(100%)',
  transition: 'transform 0.5s ease-in-out',
  marginLeft: 'auto',
  marginRight: 'auto',
//   change color of everything to white
  '*': {
    color: 'white',
  },
  zIndex: 999,
}));
 
const StyledTabButton = styled(Button)(({ theme }) => ({
    borderRadius: '5px' , 
    marginTop: '30px', 
    width: '35vw', 
    height: '50px', 
    fontFamily: 'Poppins-Bold', 
    fontSize: '20px', 
    textTransform: 'Capitalize', 
    fontWeight: 800,
    boxShadow: 'none', 
    borderBottom: '5px solid white', 
    color: 'white',
    '&:hover': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
    },
    '&.Mui-disabled': {
        borderBottom: '5px solid rgba(255, 255, 255, 0.5)',
        color: 'rgba(255, 255, 255, 0.5)',
    },
    mr: 1, 
}));

const StyledList = styled(List)(({ theme }) => ({
    '& .MuiListItem-root': {
      padding: '4px 0px',
    },
  }));
  
const StyledListItem = styled(ListItem)(({ theme }) => ({
    width: '90vw',
    height: '60px',
    borderRadius: '15px',
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    margin: '8px 0px',
}));

const users = [
  {
     name: 'User 1',
     amount: 100
  },
  {
     name: 'User 2',
     amount: 200
  },
  {
     name: 'User 3',
     amount: 300
  },
  {
    name: 'User 1',
    amount: 100
 },
 {
    name: 'User 2',
    amount: 200
 },
 {
    name: 'User 3',
    amount: 300
 },
 {
    name: 'User 1',
    amount: 100
 },
 {
    name: 'User 2',
    amount: 200
 },
 {
    name: 'User 3',
    amount: 300
 },
 {
    name: 'User 1',
    amount: 100
 },
 {
    name: 'User 2',
    amount: 200
 },
 {
    name: 'User 3',
    amount: 300
 },
 {
    name: 'User 1',
    amount: 100
 },
 {
    name: 'User 2',
    amount: 200
 },
 {
    name: 'User 3',
    amount: 300
 }
]

function HistoryPopup({ open, onClose, userId, ENDPOINT }) {
  const [activateTab, setActivateTab] = useState('received');
  const [HistoryActivity, setHistoryActivity] = useState(null);
  const [totalInteractionPoints, setTotalPointsReceived] = useState(0);

  useEffect(() => {
    const fieldObj = activateTab === 'received' ? 'from' : 'to';
    const fetchHistoryActivity = async() => {
        try {
            const response = await axios.get(`https://app.wonka.tips/api/tip-history/${userId}`);
            //  map each object in response to a new object with name and amount
            const mappedResponse = Object.entries(response.data.tipHistory[activateTab]).map(([key, value]) => ({ 
                userId: value[fieldObj].userId,
                username: value[fieldObj].username,
                firstname: value[fieldObj].firstname, 
                amount: value.amount 
            })).reverse();

            setHistoryActivity(mappedResponse);
        } catch (error) {
            setHistoryActivity(null);
        }
      }
    fetchHistoryActivity();
  }, [userId, activateTab]);

  useEffect(() => {
    if (HistoryActivity && HistoryActivity.length > 0) {
      const totalPoints = HistoryActivity.reduce((total, activity) => total + Number(parseFloat(activity.amount).toFixed(0)), 0);
      setTotalPointsReceived(totalPoints);
    }
  }, [HistoryActivity]);


  return (
    // when touched outside popup will close
    <PopupContainer sx={{ transform: open ? 'translateY(0)' : 'translateY(107%)', }} onClose={onClose}>
        {/* Close button */}
        <Box display="flex" justifyContent="flex-end" p={2} position="absolute" right={0}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        
        {/* Header */}
         <Box display="flex " flexDirection="column" justifyContent="center">
          <Typography variant="h5" position={"relative"} top={"25px"} fontFamily={"Poppins-Bold"} fontSize={"20px"} ml={2} sx={{ width: 'max-content' }}>Activity history</Typography>
          <Typography variant="h6" position={"relative"} top={"55px"} fontFamily={"Poppins-Bold"} alignSelf={"center"}>Total {activateTab === 'received' ? 'Points Received' : 'Amount You Tipped'}</Typography>
          <Typography variant="h5" position={"relative"} top={"55px"} fontFamily={"Poppins-Bold"} alignSelf={"center"}>{totalInteractionPoints}</Typography>
        </Box>

        {/* Tabs */}
         <Box display="flex " flexDirection="row" justifyContent="center" alignItems="center" mt={6}>
          {/* Vertical line */}
          <StyledTabButton onClick={() => setActivateTab('received')} sx={{ borderBottom: `5px solid ${activateTab === 'received' ? 'white' : 'rgba(255, 255, 255, 0.5)'}`}}>Received</StyledTabButton>
          <Divider sx={{ width: '1px', height: '50px', backgroundColor: 'rgba(255, 255, 255, 0.2)', marginLeft: '15px', marginRight: '15px', mt: '40px' }} />
          <StyledTabButton onClick={() => setActivateTab('sent')} sx={{ borderBottom: `5px solid ${activateTab === 'sent' ? 'white' : 'rgba(255, 255, 255, 0.5)'}`}}>Sent</StyledTabButton>
        </Box>

        {/* Show received users */}
          <Box display="flex" justifyContent="center" alignItems="center" width="100%">
            <StyledList className="custom-scrollbar" dense sx={{ maxHeight: '450px', overflow: 'auto', '& .MuiListItem-root::-webkit-scrollbar': { width: '5px', height: '5px' }, '& .MuiListItem-root::-webkit-scrollbar-track': { background: 'transparent' }, '& .MuiListItem-root::-webkit-scrollbar-thumb': { background: 'rgba(255, 255, 255, 0.2)', borderRadius: '5px' }, '& .MuiListItem-root:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' }, '& .MuiListItemIcon-root': { minWidth: '45px' }}}>
              {HistoryActivity ?
                HistoryActivity.map((user) => (
                <StyledListItem>
                  <Avatar
                    alt={user.firstname}
                    src={`${ENDPOINT}/public/users/avatars/${user.userId}.jpg`}
                    sx={{ width: 45, height: 45, ml: 1 }}
                    loading="lazy"
                  />
                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
                    <ListItemText primary={user.username} sx={{ position: 'relative', top: '5px', '& .MuiTypography-root': { color: 'white', fontFamily: 'Sora', fontWeight: 'bold', fontSize: '18px' }}} />
                    <ListItemText primary={user.firstname} sx={{ position: 'relative', bottom: '5px', '& .MuiTypography-root': { color: 'white', fontFamily: 'Sora', fontWeight: '300', fontSize: '15px' }}} />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
                      <ListItemText primary={(activateTab === 'sent' ? '-' : '+') + user.amount} sx={{ position: 'absolute', left: 'auto', right: '15px', top: '15px', '& .MuiTypography-root': { color: 'white', fontFamily: 'Sora', fontWeight: '300', fontSize: '15px' }}} />
                  </Box>
                  <IconButton
                    onClick={() => console.log('clicked')}
                    sx={{ color: 'white', position: 'absolute', right: '10px',  }}
                  >
                  </IconButton>
                </StyledListItem>
              ))
              : <Box display="flex" justifyContent="center" alignItems="center" width="100%" mt={2}>
                  <Typography variant="h5" position={"relative"} top={"55px"} fontFamily={"Poppins-Bold"} alignSelf={"center"}>No activity yet</Typography>
                </Box>}
            </StyledList>
         </Box>
    </PopupContainer>
  );
};

export default HistoryPopup;