import React from 'react';
import { Typography, List, ListItem, ListItemText, Box } from '@mui/material';
import { styled } from '@mui/system';

const LoadingRoadmap = () => {
  return (
    <Box 
      sx={{ 
        color: 'white', 
        height: '30vh', 
        padding: 2, 
        fontFamily: 'Poppins-Bold',
        position: 'relative',
        top: '13vh',
        left: '5vw'
      }}
    >
      <Typography variant="h6" sx={{ fontFamily: 'Poppins-Bold' }}>
        Pre launch:
      </Typography>
      <StyledList dense>
        <StyledListItem>
          <ListItemText primary="Early user registration" />
        </StyledListItem>
        <StyledListItem>
          <ListItemText primary="Early users farm period" />
        </StyledListItem>
      </StyledList>
      <Typography variant="h6" sx={{ fontFamily: 'Poppins-Bold' }}>
        Public launch:
      </Typography>
      <StyledList dense>
        <StyledListItem>
          <ListItemText primary="Public farming phase" />
        </StyledListItem>
        <StyledListItem>
          <ListItemText primary="Registration of volunteer channels" />
        </StyledListItem>
        <StyledListItem>
          <ListItemText primary="Start tipping on channels" />
        </StyledListItem>
        {/* <StyledListItem>
          <ListItemText primary="Launch game" />
        </StyledListItem> */}
      </StyledList>
      <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Poppins-Bold' }}>
        TGE
      </Typography>
    </Box>
  );
}

const StyledListItem = styled(ListItem)({
  paddingTop: 0,
  paddingBottom: 0,
  '&::before': {
    content: '"•"',
    display: 'inline-block',
    color: 'white',
    fontSize: '1.2rem',
    marginRight: '8px',
  }
});

const StyledList = styled(List)({
  padding: 0,
})

export default LoadingRoadmap;
