import { useState, useEffect } from 'react';

const usePreloadImages = (imageUrls) => {
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    let loadedImages = 0;
    const totalImages = imageUrls.length;

    imageUrls.forEach((url) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        loadedImages += 1;
        if (loadedImages === totalImages) {
            setImagesLoaded(true);
        }
      };
    });
  }, [imageUrls]);

  return imagesLoaded;
};

export default usePreloadImages;
