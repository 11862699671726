import React, { useEffect, useState } from 'react';
import { Button, Typography, Box, Grid, Avatar, IconButton } from '@mui/material';
import ProgressBar from './ProgressBar';
import Confetti from '../Confetti/Confetti';
import FriendsList from './FriendsList';
import axios from 'axios';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import FlutterDashIcon from '@mui/icons-material/FlutterDash';
import ConfettiGif from '../../assets/images/gifs/confetti.gif'


const Page4 = (props) => {
  const { userData, remainingLaunchTime, openConfetti, setOpenConfetti, telApp, ENDPOINT } = props;
  const [friends, setFriends] = useState(null);
  const [stopFetchingFriends, setStopFetchingFriends] = useState(false);

  useEffect(() => {
     const openConfettiFunc = () => {
       setTimeout(() => {
         setOpenConfetti(true);
       })
     }

     const fetchFriends = async () => {
       setStopFetchingFriends(true);
       const response = await axios.get(`${ENDPOINT}/referrals/${userData.id}`)
       .finally(() => {
         setStopFetchingFriends(false);
       })

       const friends = typeof response.data.referrals === 'string' ? null : response.data.referrals;
       setFriends(friends);
     }

     openConfettiFunc();
     fetchFriends();
  }, [userData]);

  const handleInviteFriends = () => {
    const message = `Heyo friend! 👋🏻
\nExciting news! Wonka.tips, the first tipping project on Telegram, is live! 🎉 Join by August 16th for exclusive early benefits 🐤.
\nDon't miss out – join now: https://t.me/WonkaTipsBot/launch?startapp=${userData.id}`;
     window.open(createShareMessageLink(message), '_blank');
  }

  const handleCopyLink = () => {
    const link = createInviteLink(userData.id);
    navigator.clipboard.writeText(link);
    telApp?.showAlert('Link copied!');
  }

  const createInviteLink = (userId) => {
    return `https://t.me/WonkaTipsBot/launch?startapp=${userId}`
  }

  const createShareMessageLink = (text) => {
    // URL-encode the text
    const encodedText = encodeURIComponent(text);
    // Format the tg://msg_url link
    return `https://t.me/share/url?url=${encodedText}`;
  }

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // background: `url(${ConfettiGif})`,
        backgroundSize: '200px',
        position: 'relative',
      }}
    >
      <ProgressBar step={4} />
      <Grid container spacing={1} alignItems="center" justifyContent="center" sx={{ backgroundColor: 'transparent', width: '95vw', borderRadius: '20px', position: 'relative', bottom: '4vh', right: '5vw', mt: 4 }}>
          <Grid item xs={3}>
            <Avatar src={'https://app.wonka.tips/api/public/users/avatars/' + userData?.id + '.jpg'} alt="Profile Photo" sx={{ width: 125, height: 125, border: '5px solid white', borderRadius: '50%' }} />
           <img src='https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Animals%20and%20Nature/Baby%20Chick.webp' width="40" height="40" style={{ color: 'white', position: 'absolute', bottom: '0vh', right: '26vw', border: '2px solid white', borderRadius: '50%', backgroundColor: 'royalblue' }} />
          </Grid>
      </Grid>
      <Typography
        align="center"
        fontFamily="Sora, sans-serif"
        fontWeight="bold"
        gutterBottom
        sx={{ color: 'white', backgroundColor: 'rgba(0,0,0,0.5)', borderRadius: '20px', width: '85vw', mx: 'auto', py: 2, px: 1, fontSize: '18px' }}
      >
         Congratulations {userData?.first_name ? userData.first_name.length > 10 ? userData.first_name.slice(0, 10) + '...' : userData.first_name : 'Loading..' || 'Anonymous'}! 
         <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Activity/Party%20Popper.webp" alt="Confetti Ball" width="25" height="25" style={{ verticalAlign: 'middle', marginLeft: '5px' }} />
      </Typography>
      <Typography
          variant="h5"
          align="center"
          fontFamily="Sora, sans-serif"
          fontWeight="bold"
          gutterBottom
          sx={{ color: 'white', backgroundColor: 'rgba(0,0,0,0.5)', borderRadius: '20px', width: '85vw', mx: 'auto', py: 2, px: 1 }}
        >
       <Box component="img" loading="lazy" src={'https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Objects/Hourglass%20Not%20Done.webp'} alt="WillyWonka" sx={{ width: '19vw', height: '14vh', position: 'absolute', left: '5vw', rotate: '-20deg' }} />
         App will live in: <br /> { remainingLaunchTime? 
            `${remainingLaunchTime.days}D ${'  '} 
             ${remainingLaunchTime.hours}H ${'  '}
             ${remainingLaunchTime.minutes}M ${'  '}
             ${remainingLaunchTime.seconds}S` : 'Loading...'}
      </Typography>
      <Typography
        variant="body1"
        align="center"
        fontFamily="Sora, sans-serif"
        fontWeight="bold"
        gutterBottom
        sx={{ color: 'white', backgroundColor: 'rgba(0,0,0,0.5)', borderRadius: '20px', width: '85vw', mx: 'auto', py: 2, px: 1 }}
      >
         You are now on the Early Members list. Invite your frens and stay tuned. The early farming phase will begin soon.
      </Typography>
      <Box
          sx={{
            width: '100vw',
            // backgroundColor: 'white',
            borderRadius: '20px',
            textAlign: 'center',
            my: 5,
            mx: 'auto',
          }}
        >
        {/* Replace this section with the actual list of invited friends */}
        <FriendsList friends={friends} stopFetchingFriends={stopFetchingFriends} isFetchingFriends={false} ENDPOINT={ENDPOINT} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleInviteFriends}
        sx={{ position: 'fixed', bottom: '0vh', left: '3vw', width: '78vw', mb: 2, py: 1.5, fontSize: '1.15rem', fontFamily: 'Sora, sans-serif', fontWeight: 'bold', borderRadius: '20px', textTransform: 'capitalize', zIndex: 999 }}
      >
        Invite frens
      </Button>
      <IconButton
          color="primary"
          aria-label="copy"
          size="medium"
          sx={{ position: 'fixed', bottom: '2vh', right: '2vw', backgroundColor: 'rgba(0,0,0,0.5)', borderRadius: '20px', zIndex: 999 }}
          onClick={handleCopyLink}
        >
        <CopyAllIcon sx={{ color: 'white', fontSize: '2rem', m: 0.75 }} /> 
      </IconButton> 
    </Box>

        {/* Confetti popup */}
        <Confetti openConfetti={openConfetti} x={0.5} y={0.85} />
        <Confetti openConfetti={openConfetti} x={0.65} y={0.24} />
        <Confetti openConfetti={openConfetti} x={0.25} y={0.44} />
    </Box>
  );
};

export default Page4;
