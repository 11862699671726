import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Typography, IconButton, Grid, Avatar,
  List, ListItem, ListItemText, CircularProgress
 } from '@mui/material';
import CopyIcon from '@mui/icons-material/CopyAllSharp';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import searchingGif from '../../assets/images/gifs/searching.gif';
import wonkaCharacter from '../../assets/images/logo/wonka-character.png';
import { styled } from '@mui/system';
import axios from 'axios';
import { Style } from '@mui/icons-material';

const PopupContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  width: '96%',
  height: '90vh',
//   makeit to linear gradient
  background: 'linear-gradient(180deg, rgb(102 140 252 / 93%) -9%, rgb(0 0 0 / 54%) 100%)',
  backdropFilter: 'blur(10px)',
  borderTopLeftRadius: '16px',
  borderTopRightRadius: '25px',
  boxShadow: theme.shadows[5],
  transform: 'translateY(100%)',
  transition: 'transform 0.5s ease-in-out',
  marginLeft: 'auto',
  marginRight: 'auto',
//   change color of everything to white
  '*': {
    color: 'white',
  },
  zIndex: 999,
}));
 
const PopupImage = styled('img')({
  width: '70px',
  height: '120px',
//   marginTop: '16px',
//   marginBottom: '16px',
  position: 'relative',
  top: '5px',
});

const PopupImage2 = styled('img')({
    position: 'absolute',
    left: '35vw',
    width: '65px',
    height: '65px',
    marginTop: '16px',
    marginBottom: '16px',
});

const StyledList = styled(List)(({ theme }) => ({
  '& .MuiListItem-root': {
    padding: '4px 0px',
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  width: '90vw',
  height: '60px',
  borderRadius: '15px',
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  margin: '8px 0px',
}));

const StyledSelectedListItem = styled(ListItem)(({ theme }) => ({
  width: '90vw',
  height: '65px',
  borderRadius: '15px',
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  margin: '8px 0px',
}));


function SendTipPopup (props) {
   const {userId, remainingAllowance,
      open, onClose, telApp, ENDPOINT} = props;
   const [searchQuery, setSearchQuery] = useState('');
   const [tipAmount, setTipAmount] = useState(null);
   const [searchResults, setSearchResults] = useState(null);
   const [userSelected, setUserSelected] = useState(null);
   const [tipLoading, setTipLoading] = useState(false);
   const [tippedUsers, setTippedUsers] = useState([]);

   useEffect(() => {
      const handleSearch = async() => {
         if (searchQuery && searchQuery !== '') {
            const response = await axios.get(`https://app.wonka.tips/api/search?search-query=${searchQuery}`);
            setSearchResults(response.data);
         } else {
            selectUser(null);
         }
      }

      handleSearch();
   }, [searchQuery]);

   const handleSendTip = async () => {
      const targetUserId = userSelected ? userSelected.userId : null;

      if (tipLoading) {
         telApp?.showAlert('Please wait while we process your tip...');
         return;
     } else if (!targetUserId) {
         telApp?.showAlert('Please select a user');
         return;
     } else if (tipAmount <= 0) {
         telApp?.showAlert('Enter an amount greater than 0');
         return;
      } else if (tipAmount > remainingAllowance) {
         telApp?.showAlert('Insufficient allowance');
         return;
      } else {
           setTipLoading(true);
          //  send tip by api key
          await axios.post(`${ENDPOINT}/tip-webapp`, { userId, targetUserId, amount: Number(tipAmount || 0) }, { headers: { 'authorization': 'Bearer C2Fslbf72-9206-4237-a523-2aa9b24d0519' } })
          .then((response) => {
            setTimeout(() => {
              setTippedUsers([...tippedUsers, targetUserId]);
              telApp?.showAlert('Success, tipped!');
            }, 2000);
          }) 
          .catch((error) => {
            telApp?.showAlert('Error, try again later!');
          })
          .finally(() => {
              setTimeout(() => {
                setTipLoading(false)
                selectUser(null);
                setSearchQuery('');
              }, 2000)
          })
      }
   }

   const selectUser = (user) => {
      setTipLoading(false);
      setSearchQuery(user ? user.username : '');
      setUserSelected(user);
   }

  return (
    // when touched outside popup will close
    <PopupContainer sx={{ transform: open ? 'translateY(0)' : 'translateY(107%)', }} onClose={onClose}>
      {/* Close button */}
      <Box display="flex" justifyContent="flex-end" p={2} position="absolute" right={0}>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box display="flex" justifyContent="center">
        <PopupImage src={wonkaCharacter} alt="Wonka" loading='lazy' />
        <PopupImage2 src={searchingGif} alt="Search" loading="lazy" />
      </Box>

      {/* Search bar and other elements */}
      <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" p={2} pb={0}>
        <Box display="flex" justifyContent="space-between" width="100%">
            <TextField
               value={searchQuery}
               onChange={(e) => setSearchQuery(e.target.value)}
               variant="outlined"
               placeholder="Search by Name or Username"
               type='text' 
               sx={{ mb: 1, width: '100%', '& .MuiOutlinedInput-root': { borderRadius: '30px', height: '55px', border: '3px solid white', }, '& .MuiOutlinedInput-input': { color: 'white !important', fontFamily: 'Sora' } }}
            /> 
        </Box>
        <Box display={userSelected === null ? 'none' : 'flex'} justifyContent="space-between" width="100%">
           <TextField
               value={tipAmount}
               onChange={(e) => setTipAmount(e.target.value)}
               variant="outlined"
               placeholder="Tip Amount"
               type='number' 
               sx={{ width: '48%', '& .MuiOutlinedInput-root': { borderRadius: '30px', height: '55px', border: '3px solid white', }, '& .MuiOutlinedInput-input': { color: 'white !important', fontFamily: 'Sora' } }}
            />
            <Grid container direction="column" justifyContent="center" alignItems="center" width="48%" border='3px solid white' borderRadius='30px'>
               <Typography variant="h6" fontSize="15px" fontFamily="Sora" fontWeight="300" color="white" position="relative" top="2px">
                 Allowance Left
               </Typography>
               <Typography variant="h6" fontSize="17px" fontFamily="Sora" fontWeight="bold" color="white">
                  {remainingAllowance}
               </Typography>
            </Grid>
        </Box>
       </Box>

        {/* Show users in table */}
      <Box display="flex" justifyContent="center" alignItems="center" width="100%" mb={2}>
          <StyledList dense sx={{ height: '45vh', overflow: 'auto' }}>
            {searchResults?
              userSelected === null ?
                searchResults.slice(0, 5).map((user) => (
                  <StyledListItem onClick={() => selectUser(user)}>
                    <Avatar
                      alt={user.username}
                      src={`${ENDPOINT}/public/users/avatars/${user.userId}.jpg`}
                      sx={{ width: 45, height: 45, ml: 1 }}
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
                      <ListItemText primary={user.username} sx={{ position: 'relative', top: '5px', '& .MuiTypography-root': { color: 'white', fontFamily: 'Sora', fontWeight: 'bold', fontSize: '18px' }}} />
                      <ListItemText primary={user.firstname} sx={{ position: 'relative', bottom: '5px', '& .MuiTypography-root': { color: 'white', fontFamily: 'Sora', fontWeight: '300', fontSize: '15px' }}} />
                    </Box>
                    <IconButton sx={{ color: 'white', position: 'absolute', right: '10px',  }}>
                        <RadioButtonUncheckedIcon />
                    </IconButton>
                    {/* <IconButton
                      onClick={() => handleSendTip(user.userId)}
                      sx={{ color: 'white', position: 'absolute', right: '10px',  }}
                    >
                      {
                        tipLoading && tipLoading.userId === user.userId ? <CircularProgress size={20} sx={{ color: 'white' }} /> : 
                        tippedUsers.includes(user.userId) ? <CheckIcon /> : <SendIcon />
                      }
                    </IconButton> */}
                  </StyledListItem>
                ))
                : <StyledSelectedListItem>
                        <Avatar
                          alt={userSelected.username}
                          src={`${ENDPOINT}/public/users/avatars/${userSelected.userId}.jpg`}
                          sx={{ width: 55, height: 55, ml: 1 }}
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
                          <ListItemText primary={userSelected.username} sx={{ position: 'relative', top: '5px', '& .MuiTypography-root': { color: 'white', fontFamily: 'Sora', fontWeight: 'bold', fontSize: '18px' }}} />
                          <ListItemText primary={userSelected.firstname} sx={{ position: 'relative', bottom: '5px', '& .MuiTypography-root': { color: 'white', fontFamily: 'Sora', fontWeight: '300', fontSize: '15px' }}} />
                        </Box>
                        <IconButton sx={{ color: 'white', position: 'absolute', right: '10px',  }} >
                            <RadioButtonCheckedIcon />
                        </IconButton>
                   </StyledSelectedListItem>
              : null
            }
          </StyledList>

       <Button 
            variant="contained"
            color="primary"
            onClick={handleSendTip}
            sx={{
              position: 'absolute', 
              opacity: userSelected === null ? 0.5 : 1,
              left: '48vw',
              bottom: '2.5vh',
              transform: 'translate(-50%, 0%)',
              width: '90vw',
              height: '9vh',
              borderRadius: '20px',
              textTransform: 'none',
              fontFamily: 'Sora',
              fontSize: '18px',
              '&:hover': {
                border: '3px solid white',
              }
            }}
           >
             {tipLoading ? 
                 <span>Sending... <CircularProgress size={20} thickness={5} disableShrink sx={{ color: 'white', ml: 1, position: 'relative', top: '2px' }} /></span> : 
                 <span>Send Tip <SendIcon sx={{ fontSize: '22px', ml: 1, position: 'relative', top: '5  px' }} /></span>
             }
          </Button>
       </Box>
    </PopupContainer>
  );
};

export default SendTipPopup;